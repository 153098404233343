<template>
  <div class="coming-soon">
    <h3>Coming Soon.</h3>
  </div>
</template>

<script>
export default {
  name: "ComingSoon"
};
</script>

<style scoped lang="scss">
.coming-soon {
  // ...
}
</style>
